@font-face {
  font-family: PermanentMarker;
  src: url(../public/fonts/PermanentMarker-Regular.ttf);
}

body {
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-size: 12px;
  color: #fff !important;
  background-color: #000 !important;
  text-align: justify !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
nav {
  font-family: PermanentMarker;
}

.container {
  max-width: 800px !important;
}

.navbar {
  background-image: linear-gradient(#9c6fb8 95%, #23282d);
  padding-left: 50px !important;
  padding-right: 50px !important;
}

footer {
  background-image: linear-gradient(to top, #9c6fb8 95%, #23282d);
  color: #000;
}

.bi-telegram {
  color: #3390ec;
  font-size: 3em;
}

.bi-telegram:hover {
  color: #fff;
}

a {
  color: #9c6eb8;
}

a:hover {
  color: #6c4981;
}

.nav-link {
  font-size: large;
  color: #000 !important;
}

.nav-item {
  margin-right: 1em;
}

.nav-link:hover {
  color: #fff !important;
}

@media (max-width: 900px) {
  .nav-link {
    font-size: 3em;
  }
}

.card {
  background-color: #000 !important;
}

.card-header:hover {
  cursor: pointer;
}

.headerText {
  white-space: nowrap;
  font-size: 1.5em;
}

.mailLink {
  text-decoration: none;
  color: #000;
}

.mailLink:hover {
  color: #fff;
}

.headerIcons {
  font-size: 2em;
  color: #000;
}

.headerIcons:hover {
  color: #fff;
}

.footerIcons {
  font-size: 3em;
  color: #000;
}

.footerIcons:hover {
  color: #fff;
}

.nav-bar-container {
  display: flex;
}

@media (max-width: 1200px) {
  .nav-bar-container {
    display: contents;
  }
}
